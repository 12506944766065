.App {
    background: #100045;
    color: white;
    height: 100vh;
    text-align: center;
    font-size: 3em;
    width: auto;
}

.Logo {
    height: 20vh;
    margin: 10% auto 0 auto;
}

.signup-redirect {
    background: #100045;
    color: white;
    height: 100vh;
    margin: 0;
    padding: 1em;
}